<template>
  <!-- Zoom settings -->
  <fieldset>
    <legend class="h6">
      <i class="accent--text fas fa-video"></i>
      <span>&nbsp;Meeting</span>
    </legend>

    <!-- Zoom "Join" Link -->
    <label for="zoomLink" v-if="form.zoomLink && !form.createZoom">
      <span class="h6">
        <i class="accent--text fas fa-video"></i>
        <span>&nbsp;Join {{ type }}:</span>
      </span>

      <span class="flex wide">
        <input
          :disabled="!isAdminUser"
          class="grow"
          name="zoomLink"
          placeholder="Paste Meeting Link here"
          style="margin: 0"
          type="text"
          v-model="form.zoomLink"
        />

        <hr class="divider divider--vertical" />

        <div class="inline--row no-shrink">
          <button
            @click.prevent="appendAndEmit(zoomResetData)"
            class="outline no-shrink"
            style="font-size: small; margin: 0"
          >
            <i class="fas fa-plus-circle success--text"></i>
            New
          </button>

          <button
            @click.prevent="appendAndEmit({ zoomLink: null })"
            class="outline error--text"
            style="font-size: small; margin: 0; box-shadow: none"
          >
            <span class="error--text">Reset</span>
          </button>
        </div>
      </span>

      <hr class="divider divider--tiny" />
    </label>

    <div class="column" v-else>
      <label v-if="!form.createZoom" for="createZoom" class="flex form--row">
        <!-- Schedule Zoom -->
        <span class="flex col-50" @click.prevent="toggleCreateZoom">
          <input
            type="checkbox"
            name="createZoom"
            v-model="form.createZoom"
            @change="emitFormData"
          />
          <span class="checkbox-label">Schedule Zoom Meeting</span>
        </span>

        <!-- Paste URL -->
        <span
          class="flex col-50"
          @click.prevent="
            appendAndEmit({ createZoom: false, zoomLink: 'https://zoom.us/' })
          "
        >
          <input type="checkbox" name="createZoom" />
          <span class="checkbox-label">Paste Meeting URL</span>
        </span>
      </label>

      <div v-else class="slide-down-fade-in flex wide">
        <label for="meetingLength" class="col-25">
          <span class="h6 flex">
            <i class="accent--text fas fa-clock"></i>
            <hr class="divider divider--vertical" />
            <span>&nbsp;Length (mins)</span>
          </span>

          <input
            type="number"
            step="15"
            name="meetingLength"
            min="30"
            :disabled="!form.createZoom"
            v-model="form.meetingLength"
            placeholder="60"
            @input="emitFormData"
          />
        </label>

        <hr class="divider divider--vertical" />

        <label for="meetingPassword" class="grow">
          <span class="h6 flex">
            <i class="accent--text fas fa-clock"></i>
            <hr class="divider divider--vertical" />
            <span>&nbsp;Passcode</span>
          </span>

          <input
            type="text"
            name="meetingPassword"
            :disabled="!form.createZoom"
            v-model="form.meetingPassword"
            maxlength="10"
            placeholder="(Optional) password to join meeting"
            @input="emitFormData"
          />
        </label>

        <hr class="divider divider--vertical" />

        <button
          @click.prevent="appendAndEmit({ createZoom: false })"
          class="outline error--text"
          style="font-size: small; margin: 2.2rem 0 0; box-shadow: none"
        >
          <span class="error--text">Cancel</span>
        </button>
      </div>
    </div>
  </fieldset>
</template>

<script>
import FormsMixin from "./mixins/forms.mixin";
import PermissionsMixin from "./mixins/permissions.mixin";

export default {
  name: "EventFormZoom",

  mixins: [FormsMixin, PermissionsMixin],

  props: {
    isOnlineClass: Boolean,
    teachers: { type: Array, default: () => [] }
  },

  computed: {
    requiredFields() {
      const fields = [
        "createZoom",
        "zoomLink",
        "zoomStartLink",
        "zoomMeetingId"
      ];
      if (this.form.createZoom) fields.push("meetingPassword");
      return fields;
    },

    type() {
      return this.isOnlineClass ? "Class" : "Event";
    },

    zoomResetData() {
      return { createZoom: true, meetingLength: 60 };
    }
  },

  methods: {
    requiredSubfield(field) {
      if (!this.form.createZoom) return `h6 flex`;
      return `${this.requiredField(field)} flex`;
    },

    toggleCreateZoom() {
      const createZoom = !this.form.createZoom;
      let params;

      if (createZoom) {
        const { assignedTeacher: teacherId } = this.form;
        const teacher = this.teachers.find(({ id }) => id === teacherId);
        const zoomUserId = (teacher || { zoomUserId: null }).zoomUserId;
        params = { ...this.zoomResetData, zoomUserId };
      } else params = { createZoom };

      return this.appendAndEmit(params);
    }
  }
};
</script>
