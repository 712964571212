<template>
  <fieldset class="event-schedule">
    <legend class="h6">
      <i class="accent--text fas fa-clock"></i>
      Schedule
    </legend>

    <label for="scheduledDate" class="grow">
      <span :class="requiredField('scheduledDate')">
        <i class="accent--text fas fa-calendar"></i>
        <span>&nbsp;Scheduled Date</span>
      </span>

      <datepicker
        :format="dateTimeShortFormat"
        :hour-time="12"
        :minute-step="15"
        :value-format="dateInputFormat"
        :value="form.scheduledDate"
        @input="appendAndEmit({ scheduledDate: $event })"
        no-toggle-time-picker
        start-from-sunday
      />
    </label>

    <hr class="divider divider--vertical" />

    <time-zone-picker
      zoom-time-zones
      class="column"
      required
      :value="form.scheduledTimeZone"
      :invalid="invalidFields.includes('scheduledTimeZone')"
      @input="({ text }) => appendAndEmit({ scheduledTimeZone: text })"
    />

    <!-- Weekly Event -->
    <label
      for="eventRepeats"
      class="event-repeats flex"
      @click.stop="
        appendAndEmit({
          isRepeatingEvent: !form.isRepeatingEvent,
          scheduledEndDate: !form.isRepeatingEvent
            ? null
            : form.scheduledEndDate
        })
      "
    >
      <input
        type="checkbox"
        @change="clearErrors"
        name="eventRepeats"
        v-model="form.isRepeatingEvent"
      />
      <hr class="divider divider--vertical" />
      <span>This is a weekly event</span>
    </label>

    <!-- Repeating End Date -->
    <label for="scheduledEndDate" class="grow" v-if="form.isRepeatingEvent">
      <span :class="requiredField('scheduledEndDate')">
        <i class="accent--text fas fa-calendar"></i>
        <span>&nbsp;End Date (repeating events/classes)</span>
      </span>

      <datepicker
        :format="dateTimeShortFormat"
        :hour-time="12"
        :min-date="minEndDate"
        :minute-step="15"
        :value-format="dateInputFormat"
        :value="form.scheduledEndDate"
        @input="coerceEndDate($event)"
        no-toggle-time-picker
        start-from-sunday
      />
    </label>
  </fieldset>
</template>

<script>
import { dateTimeFromJS } from "../helpers/date-time.helpers";
import DateTimeMixin from "./mixins/date-time.mixin";
import FormsMixin from "./mixins/forms.mixin";
import TimeZonePicker from "./TimeZonePicker.vue";

export default {
  name: "EventFormSchedule",

  components: { TimeZonePicker },

  mixins: [DateTimeMixin, FormsMixin],

  computed: {
    minEndDate() {
      const isoDate = this.form.scheduledDate;
      return this.utcFromISO(isoDate, this.localTZ).plus({ days: 1 });
    },

    requiredFields() {
      const fields = ["scheduledDate", "scheduledTimeZone"];
      if (this.form.isRepeatingEvent)
        fields.push("isRepeatingEvent", "scheduledEndDate");

      return fields;
    }
  },

  methods: {
    coerceEndDate(date) {
      const coerced =
        typeof date === "string"
          ? date
          : dateTimeFromJS(date).toFormat(this.dateInputFormat);
      return this.appendAndEmit({ scheduledEndDate: coerced });
    }
  }
};
</script>

<style lang="scss">
.event-schedule {
  @include flex;
  flex-wrap: wrap;

  .autocomplete {
    width: $half;
  }

  .event-repeats {
    width: $parent;
  }
}

@media screen and (max-width: 600px) {
  .event-schedule {
    flex-direction: column;

    .autocomplete,
    > * {
      width: $parent;
    }
  }
}
</style>
