var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',{staticClass:"event-schedule"},[_vm._m(0),_c('label',{staticClass:"grow",attrs:{"for":"scheduledDate"}},[_c('span',{class:_vm.requiredField('scheduledDate')},[_c('i',{staticClass:"accent--text fas fa-calendar"}),_c('span',[_vm._v(" Scheduled Date")])]),_c('datepicker',{attrs:{"format":_vm.dateTimeShortFormat,"hour-time":12,"minute-step":15,"value-format":_vm.dateInputFormat,"value":_vm.form.scheduledDate,"no-toggle-time-picker":"","start-from-sunday":""},on:{"input":function($event){return _vm.appendAndEmit({ scheduledDate: $event })}}})],1),_c('hr',{staticClass:"divider divider--vertical"}),_c('time-zone-picker',{staticClass:"column",attrs:{"zoom-time-zones":"","required":"","value":_vm.form.scheduledTimeZone,"invalid":_vm.invalidFields.includes('scheduledTimeZone')},on:{"input":function (ref) {
      var text = ref.text;

      return _vm.appendAndEmit({ scheduledTimeZone: text });
}}}),_c('label',{staticClass:"event-repeats flex",attrs:{"for":"eventRepeats"},on:{"click":function($event){$event.stopPropagation();return _vm.appendAndEmit({
        isRepeatingEvent: !_vm.form.isRepeatingEvent,
        scheduledEndDate: !_vm.form.isRepeatingEvent
          ? null
          : _vm.form.scheduledEndDate
      })}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.isRepeatingEvent),expression:"form.isRepeatingEvent"}],attrs:{"type":"checkbox","name":"eventRepeats"},domProps:{"checked":Array.isArray(_vm.form.isRepeatingEvent)?_vm._i(_vm.form.isRepeatingEvent,null)>-1:(_vm.form.isRepeatingEvent)},on:{"change":[function($event){var $$a=_vm.form.isRepeatingEvent,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "isRepeatingEvent", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "isRepeatingEvent", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "isRepeatingEvent", $$c)}},_vm.clearErrors]}}),_c('hr',{staticClass:"divider divider--vertical"}),_c('span',[_vm._v("This is a weekly event")])]),(_vm.form.isRepeatingEvent)?_c('label',{staticClass:"grow",attrs:{"for":"scheduledEndDate"}},[_c('span',{class:_vm.requiredField('scheduledEndDate')},[_c('i',{staticClass:"accent--text fas fa-calendar"}),_c('span',[_vm._v(" End Date (repeating events/classes)")])]),_c('datepicker',{attrs:{"format":_vm.dateTimeShortFormat,"hour-time":12,"min-date":_vm.minEndDate,"minute-step":15,"value-format":_vm.dateInputFormat,"value":_vm.form.scheduledEndDate,"no-toggle-time-picker":"","start-from-sunday":""},on:{"input":function($event){return _vm.coerceEndDate($event)}}})],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('legend',{staticClass:"h6"},[_c('i',{staticClass:"accent--text fas fa-clock"}),_vm._v(" Schedule ")])}]

export { render, staticRenderFns }