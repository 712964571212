<template>
  <fieldset class="form--row wrap">
    <legend class="h6">
      <i class="accent--text fas fa-unlock"></i>
      {{ title }}
    </legend>

    <label v-if="!isOnlineClass" for="eventCategory" class="col-33">
      <span :class="requiredField('eventCategory')">
        <i class="accent--text fas fa-object-group"></i>
        <span>&nbsp;Event Category</span>
      </span>
      <select
        name="eventCategory"
        v-model="form.eventCategory"
        @change="emitFormData"
        aria-placeholder="Select a Category:"
      >
        <option v-for="(option, i) in eventCategories" :key="i" :value="option">
          {{ option }}
        </option>
      </select>
    </label>

    <hr class="divider divider--vertical" />

    <label for="eventStatus" class="grow">
      <span :class="requiredField('eventStatus')">
        <i class="accent--text fas fa-unlock"></i>
        <span>&nbsp;Status</span>
      </span>
      <select
        name="eventStatus"
        v-model="form.eventStatus"
        @change="emitFormData"
        aria-placeholder="Select an Event Status:"
      >
        <option v-for="status in statuses" :key="status" :value="status">
          {{ status }}
        </option>
      </select>
    </label>

    <hr class="divider divider--vertical" />

    <label for="eventCap" class="col-33">
      <span :class="requiredField('eventCap')">
        <i class="accent--text fas fa-user-friends"></i>
        <span>&nbsp;Max Participants</span>
      </span>

      <input
        type="number"
        name="eventCap"
        v-model="form.eventCap"
        min="0"
        placeholder="e.g. 12"
        @input="emitFormData"
      />
    </label>
  </fieldset>
</template>

<script>
import FormsMixin from "./mixins/forms.mixin";
import {
  eventCategories,
  ONLINE_CLASS,
  statuses
} from "../helpers/classes-events.helpers";

export default {
  name: "EventFormParticipants",

  mixins: [FormsMixin],

  props: { isOnlineClass: Boolean },

  data() {
    const form = { eventCap: 25 };
    return { eventCategories, form, statuses };
  },

  computed: {
    requiredFields() {
      const required = ["eventCategory", "eventStatus", "eventCap"];
      return required;
    },

    title() {
      const base = `Registration`;
      return this.isOnlineClass ? base : `Category & ${base}`;
    }
  },

  mounted() {
    if (this.isOnlineClass) return;

    if (this.formData.eventCategory === ONLINE_CLASS) {
      this.appendFormData({ eventCategory: eventCategories[0] });
      this.emitFormData();
    }
  }
};
</script>
