<template>
  <fieldset class="flex wrap">
    <legend class="h6">
      <i class="accent--text fas fa-image"></i>
      {{ type }} Image
    </legend>

    <label for="classImage" class="grow">
      <span :class="requiredField('eventImage')">
        <i class="accent--text fas fa-image"></i>
        <span>&nbsp;Image URL</span>
      </span>

      <input
        type="text"
        name="classImage"
        v-model="form.eventImage"
        placeholder="e.g. https://cdn.myassets.domain/file.png"
        @input="emitFormData"
      />
    </label>

    <hr class="divider divider--vertical" />

    <label for="classImageFile" class="col-33">
      <span :class="requiredField('imageFile')">
        <i class="accent--text fas fa-image"></i>
        <span>&nbsp;Upload File</span>
      </span>

      <input
        type="file"
        accept="image/*"
        name="classImage"
        @input="setImageFile($event.target)"
      />
    </label>
  </fieldset>
</template>

<script>
import FormsMixin from "./mixins/forms.mixin";

export default {
  name: "EventFormImage",

  mixins: [FormsMixin],

  props: { isOnlineClass: Boolean },

  computed: {
    requiredFields() {
      return ["eventImage", "imageFile"];
    },

    type() {
      return this.isOnlineClass ? "Class" : "Event";
    }
  }
};
</script>
