<template>
  <aside class="column controls--centered">
    <branded-divider />

    <p class="description">
      <b>Note:</b> This action cannot be reversed, and students
      <strong>will not</strong> be automatically notified. If you are sure, use
      the button below to permanently delete
      <strong class="accent--text">{{ event.name }}</strong
      >.
      <span v-if="event.zoomMeetingId">
        The attached zoom meeeting will also be deleted.
      </span>
    </p>

    <branded-divider />

    <p class="error--text" v-if="error">{{ error }}</p>

    <button class="error" @click.prevent="$emit('confirm-delete')">
      <i class="fas fa-times-square"></i>
      Confirm Delete
    </button>
  </aside>
</template>

<script>
import BrandedDivider from "./BrandedDivider";

export default {
  name: "ConfirmDeleteEvent",

  components: { BrandedDivider },

  props: { event: Object, error: String }
};
</script>

<style lang="scss" scoped>
.description {
  padding-bottom: $md;
  padding-top: $md;
}
</style>
