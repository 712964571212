/* eslint-disable no-undef */
import FormsMixin from "./forms.mixin";
import PermissionsMixin from "./permissions.mixin";
import { eventFormReset, statuses } from "@/helpers/classes-events.helpers";
import { getTeachers } from "@/models/user";
import { getEventById, createOrUpdateEvent } from "@/models/events";
import EventsMixin from "./events.mixin";
import { invalidFormMessage } from "../../helpers/form.helpers";
import { deleteEvent } from "../../models/events";

/**
 * For components that hold an `Event` or `Online Class` form
 */
const EventFormsMixin = {
  mixins: [EventsMixin, FormsMixin, PermissionsMixin],

  data() {
    return {
      confirmDelete: false,
      event: null,
      loading: true,
      onlineClass: null,
      statuses,
      teachers: [],
      validationError: null
    };
  },

  computed: {
    /*
     * Inherited from Events.Mixin:
     *
     * - classId
     * - eventDate
     * - eventId
     * - formattedTime
     * - formattedTimeLocal
     * - isOnlineClass
     * - listRoute
     * - type
     */

    formIsValid() {
      const { form } = this;
      const conditions = [
        Boolean(form.name),
        Boolean(form.description),
        Boolean(form.eventCategory),
        Boolean(form.imageFile || form.eventImage),
        Boolean(form.assignedTeacher),
        Boolean(form.scheduledDate && form.scheduledTimeZone)
      ];

      if (form.createProduct === true) {
        conditions.push(
          Boolean(form.shortDescription),
          Boolean(form.eventPrice)
        );
      }

      if (form.isRepeatingEvent === true) {
        //conditions.push(Boolean(form.scheduledEndDate));
      }

      return conditions.every(condition => condition);
    },

    requiredFields() {
      const fields = [
        "assignedTeacher",
        "description",
        "eventCategory",
        "eventImage",
        "eventStatus",
        "name"
      ];

      if (this.form.isRepeatingEvent) {
        fields.push("isRepeatingEvent");
      }

      if (this.form.createProduct) {
        fields.push("eventPrice", "shortDescription");
      }

      return fields;
    }
  },

  async mounted() {
    this.startLoading("Starting Editor...");
    // Init teachers list (for admins only)
    if (this.isAdminUser) this.teachers = await getTeachers();
    // Init as route
    await this.fetchRouteData();
    this.stopLoading();
  },

  methods: {
    appendFormData(data) {
      this.form = { ...this.form, ...data };
      this.clearErrors();
    },

    async deleteEvent() {
      try {
        this.startLoading(`Deleting ${this.type} ... `);
        await deleteEvent(this.data);
        this.stopLoading();
        this.$router.push({ name: this.listRoute });
      } catch (error) {
        this.onFormError(error.message || error);
      }
    },

    async fetchRouteData() {
      this.startLoading("Fetching data ...");
      const { eventId, classId } = this;
      const type = this.type;

      if (eventId || classId) {
        try {
          const data = await getEventById(eventId || classId);
          document.title = `${type} • Edit ${type} • ${APP_ORG} Admin`;
          this.hydrate(data);
        } catch (error) {
          this.onFormError(error);
        }
      } else {
        document.title = `${type} • Create ${type} • ${APP_ORG} Admin`;
        this.stopLoading();
      }
    },

    hydrate(data) {
      if (this.isOnlineClass) {
        this.onlineClass = data;
      } else this.event = data;
      this.form = { ...data };
      this.hydrateTeachers(data);
      // set date
      const date = { scheduledDate: this.eventDate.toFormat("yyyy-MM-dd'T'T") };
      this.appendFormData(date);
      // finish
      this.stopLoading();
    },

    hydrateTeachers(event) {
      if (!this.isAdminUser) return;

      ["assignedTeacher", "backupTeacher"].forEach(key => {
        const assignee = event[key] || {};
        const teach = this.teachers.find(t => t.fullName === assignee.name);

        if (teach) {
          this.appendFormData({
            [key]: assignee.name === "No Instructor assigned" ? null : teach.id
          });
        }
      });
    },

    initializeForm() {
      this.form = eventFormReset(this.isOnlineClass);
      if (!this.activeUser) return;
      this.appendFormData({ scheduledTimeZone: this.activeUser.timeZone });
    },

    returnToViewRoute({ type, id } /* = data */) {
      this.stopLoading();
      const name = `View${type}`;
      const idKey = `${type.toLowerCase()}Id`;

      if (id) {
        this.$router.push({ name, params: { [idKey]: id } });
      }
    },

    async submitEventForm() {
      this.clearErrors();
      const params = { ...this.form };

      // Validate
      if (!this.formIsValid) {
        const imgFields = ["eventImage", "imageFile"];
        const reqFields = this.requiredFields;
        this.onFormError(invalidFormMessage(params, reqFields, imgFields));
        return;
      }

      const srcEvent = this.event || this.onlineClass || null;
      const action = srcEvent ? "Updating" : "Creating";
      if (params.createZoom && !params.zoomUserId) {
        const matchTeacher = t => t.id === params.assignedTeacher;
        const teacher = this.teachers.find(matchTeacher);
        params.zoomUserId = (teacher || { zoomUserId: null }).zoomUserId;
      }

      try {
        this.startLoading(`${action} ${this.type}`);
        this.event = await createOrUpdateEvent(params, srcEvent);
        this.returnToViewRoute(this.event);
      } catch (error) {
        this.onFormError(error.message || error);
      }
    }
  }
};

export default EventFormsMixin;
