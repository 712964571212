<template>
  <fieldset class="flex wrap">
    <legend class="h6">
      <i class="accent--text fas fa-chalkboard-teacher"></i>
      Instructors
    </legend>

    <!-- Assigned teacher -->
    <mvp-instructor-select
      :value="form.assignedTeacher"
      @change="onAssignedTeacher($event)"
      class="grow"
      instructor-type="Teachers"
      label-text="Teacher"
      required
    />

    <hr class="divider divider--vertical" />

    <!-- Backup Teacher -->
    <mvp-instructor-select
      :value="form.backupTeacher"
      @change="appendAndEmit({ backupTeacher: $event.teacher })"
      class="col-50"
      instructor-type="Teachers"
      label-text="Substitute"
    />

    <!-- Error message -->
    <div class="list-item list-item--label error--text" v-if="error">
      <i class="fas fa-exclamation-circle error--text" />
      <hr class="divider divider--vertical" />
      {{ error }}
    </div>
  </fieldset>
</template>

<script>
import FormsMixin from "./mixins/forms.mixin";
import MvpInstructorSelect from "./MvpInstructorSelect.vue";

export default {
  name: "EventFormTeachers",

  mixins: [FormsMixin],

  components: { MvpInstructorSelect },

  props: { teachers: { type: Array, required: true } },

  data: () => ({
    requiredFields: ["assignedTeacher", "backupTeacher", "zoomUserId"]
  }),

  mounted() {
    if (!this.formData || !this.teachers) return;
    if (!this.formData.zoomUserId) {
      const { assignedTeacher } = this.formData;
      const teacher = this.teachers.find(({ id }) => id === assignedTeacher);
      const zoomUserId = teacher || { zoomUserId: null };
      return this.appendFormData({ zoomUserId });
    }
  },

  methods: {
    clearErrors() {
      this.error = null;
    },

    onAssignedTeacher({ data, teacher: assignedTeacher }) {
      const zoomUserId = (data || { zoomUserId: null }).zoomUserId;
      this.clearErrors();
      this.appendAndEmit({ assignedTeacher, zoomUserId });
      if (data) this.validateTeacher(data);
    },

    validateTeacher({ id, firstName, zoomUserId }) {
      if (id && firstName && !zoomUserId) {
        this.onFormError(`${firstName} does not have a zoom account!`);
      }
    }
  }
};
</script>
